<template>
  <div class="ccontainer-fluid navbar_bg p-4 fixed-bottom">
    <div class="row">
      <div class="col-md-10 mx-auto">
        <div class="d-flex justify-content-between align-items-bottom">
          <span class="subline_20" style="font-size: 16px">&copy; {{ currentYear }} VPN One Click Professional</span>
          <span> <a href="https://www.vpnoneclick.com/contact/" target="_blank" class="subline_20" style="font-size: 16px;color:#007CA6;"><b>Contact Us</b></a></Span>
        </div>
      </div>
    </div>
  </div>
</template>
 
 <script>
export default {
  name: "FooterVue",
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
};
</script>
 
 <style scoped>
.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Adjust z-index as needed */
}
.subline_20 {
  font-weight: normal !important;
}
</style>