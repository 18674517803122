<template>
  <nav class="navbar navbar-expand-lg navbar-light navbar_bg fixed-top">
    <div class="container">
      <router-link :to="{ name: 'dashboard' }" class="text-decoration-none navbar-brand">
        <img src="/assets/img/VPNONECLICK-LOGO.png" alt style="height: 50px; width:50px;" />
        <span class="subline_20" style="font-size: 16px">VPN One Click Professional</span>
      </router-link>

      <div v-if="currentPath !== '/account-profile'">
        <router-link
          v-if="islogin && !isUserProfileRoute"
          :to="{ name: 'userProfile' }"
          class="text-decoration-none login_btn sm-margin-top"
        >
          <span class="subline_24 p-3 text-white" style="font-family: cursive;">Profile</span>
        </router-link>
        <router-link
          v-if="isResellerlogin"
          :to="{ name: 'resellerProfile' }"
          class="text-decoration-none login_btn sm-margin-top"
        >
          <span class="subline_24 p-3 text-white" style="font-family: cursive;">Profile</span>
        </router-link>
      </div>
      <div v-else>
        <router-link
          :to="{ name: 'accountProfile' }"
          class="text-decoration-none login_btn sm-margin-top"
        >
          <span class="subline_24 p-3 text-white">My Account</span>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "HeaderVue",
  computed: {
    islogin() {
      const user = this.$store.state.auth.user?.message?.user;
      return (
        user &&
        this.$store.state.auth.status.loggedIn &&
        (user.user_type == "3" ||
          (user.user_type == "2" && user.user_level == undefined) ||
          user.user_type == "1")
      );
    },
    isResellerlogin() {
      const user = this.$store.state.auth.user?.message?.user;
      return (
        user && this.$store.state.auth.status.loggedIn && user.user_level == "4"
      );
    },
    currentPath() {
      return this.$route.path;
    },
    isUserProfileRoute() {
      return this.$route.name === "userProfile";
    }
  }
};
</script>

<style scoped>
@media (max-width: 615px) {
  .sm-margin-top {
    margin-top: 30px;
    /* margin-left: 30px; */
  }
}
</style>
